import React, { useState, useEffect } from "react";
import logo from "./logo.svg";
//import './App.css';
import styled from "styled-components";
import firebase, { storage, auth, provider } from './firebase.js';
import {CustRed, CustYellow, CustBlue, CustGrey, ButtonColor, DarkBackgroundColor, CustOrange, DarkTextColor} from './colors';
import NavBar from './NavBar';
import Submission from './Submission'

export const ActionButton = styled.button`
    padding: 10px 20px;
    background-color: ${ButtonColor};
    color: white;
    font-size: 16px;
    cursor: pointer;
    transition: all .2s linear;
    &:hover{
        opacity: 0.7;
    }
    -webkit-appearance: none;
    -moz-appearance: none;
    border: 0px solid transparent;

    @media(max-width: 992px){
      font-size: 12px;
      padding: 5px 10px;
  }
`
export const NavButton = styled(ActionButton)`
    margin: 10px;
`


export const ContentContainer = styled.div`
  width: 1040px;
  height: 100%;
  margin: auto;
  padding: 50px 50px;
  background-color: ${DarkBackgroundColor};

  a{
    color: ${ButtonColor};
  }

  @media(max-width: 992px){
    width: 100%;
    box-sizing: border-box;
    padding: 20px;
  }
`;

const SubmissionGrid = styled.div`
  display: flex;
  flex-wrap: wrap;
  width: 100%;

`

const SubmissionsHeader = styled.h2`
  margin-left: 9px;
  border-bottom: 2px dashed ${CustRed};
  margin: 10px 10px;
  padding-bottom: 5px;

  span{
    font-family: futura;
    color: ${CustRed};
  }
`

const InstructionContainer = styled.div`
display:flex;
flex-direction: row;
color: ${DarkTextColor};

@media(max-width: 992px){
  flex-direction: column;
}
`

const Panel = styled.div`
margin: 10px;
background-color: ${CustGrey};
padding: 15px;
line-height: 25px;
font-family: 'Raleway';

a{ font-weight: bold; }


`
const HeroText = styled.h1`
font-size: 64px;

@media(max-width: 992px){
  font-size: 42px;
}
`

const NoSubmissions = styled.div`
text-align: center;
width: 100%;
margin: 10px;
margin-top:30px;
padding: 100px;
border: 2px dashed ${CustRed};
`

const ShowMoreButton = styled(ActionButton)`
  width: 100%;
  margin: 10px;
`

const ThemeContainer = styled.div`
  text-align: center;
  background-color: ${ButtonColor};
  margin: 10px 10px;
  padding: 20px;
`

const IntroContainer = styled.div`
text-align: center;
width: 800px;
margin: auto;

p {    
  margin: 10px 0px;
}

h2{
  font-size: 33px;

  @media(max-width:992px){
  font-size: 24px;
  }
}

h3{
  font-size: 20px;
  margin: 6px;
}

@media(max-width: 992px){
  width: 100%;
}
`
const submissionsRef = firebase.database().ref('submissions');

const WatchLinks = () => {
  return (
  <>
  <a href="https://discord.gg/fTFgG9y">Discord</a> / <a href = "http://www.twitch.tv/WondervilleNYC">Twitch</a>
  </>
  )
}
const App = () => {

  const id = 3;
  const [submissions, setSubmissions] = useState<any[]>([])
  const [submissionsToShow, setSubmissionsToShow] = useState<number>(12)
  const [user, setUser] = useState<any>(null);
  const [submissionCount, setSubmissionCount] = useState<number>(0)

  useEffect(() => {
    console.log("use effect")
    // Update the document title using the browser API
    submissionsRef.on('value', (snapshot) => {
      console.log("submissions ref")
      console.log(snapshot.val());
      let items = snapshot.val();
      let submissionsList = [];
      for (let item in items) {
        submissionsList.push(items[item]);
      }
      // sort by timestamp
      const sortedSubmissionsList = submissionsList.sort((a, b) => b.lastUpdated - a.lastUpdated)
      
      setSubmissions(sortedSubmissionsList)
      setSubmissionCount(sortedSubmissionsList.length)
    });

    auth.onAuthStateChanged((user) => {
      if (user) {
        setUser(user);
      }
    });
  }, []);


  const logout = () => {
    auth.signOut()
      .then(() => {
        setUser(null);
      });
  }

  const login = () => {
    auth.signInWithPopup(provider)
      .then((result) => {
        const user = result.user;
        setUser(user);
      });
  }

  const showMore = () =>{
    var newSubmissionsToShow = submissionsToShow + 3;
    setSubmissionsToShow(newSubmissionsToShow);
  }

  const listSubmissions = () => {
    if (submissions.length > 0){
    return submissions.slice(0, submissionsToShow).map((item) => <Submission item={item}/>)
    } else {
      return <NoSubmissions>No submissions yet! Why not <a href = "/submit">be the first</a>!</NoSubmissions>
    }
  }
  return (
    <>
      <NavBar />

      <ContentContainer>
        <IntroContainer>
          <HeroText>Super Social Distance Jam</HeroText>
          <h2>A remote gamejam</h2>
          <h3>May 22 - 24, 2020</h3>
          <p>Join Wonderville and Death by Audio Arcade for a weekend-long gamejam from home! Share your work, meet other designers and developers, and check out creations from the community... from home.</p>
        </IntroContainer>
        <ThemeContainer><strong>Theme:</strong> Being Alone / Being Together</ThemeContainer>
        <InstructionContainer>
          <Panel>
            <h2>Schedule:</h2>
            <ul>
              <li><strong>Friday, May 22 @ 8:00PM:</strong> Kickoff, theme announcement, team formation on {WatchLinks()}</li>
              <li><strong>Friday, May 22 @ 10:00PM - late:</strong> Music and performances on Twitch! (Lineup coming soon)</li>
              <li><strong>Saturday, May 23 @ 8:00PM:</strong> Check-in and share on {WatchLinks()}</li>
              <li><strong>Saturday, May 23 @ 10:00PM - late:</strong> Music and performances on Twitch! (Lineup coming soon)</li>
              <li><strong>Saturday, May 23 @ 11:00PM-3:00AM:</strong> Late-night insomnia jam session on Discord!</li>
              
              <li><strong>Sunday, May 24 @ 6:30PM:</strong> <a href = "https://docs.google.com/spreadsheets/d/1xcniz0N-givw9DhbffLavlhAaKE7T6FzK0XU0Us6Hsk/edit#gid=0">Sign up</a> if you would like to present your project on Discord.</li>
              <li><strong>Sunday, May 24 @ 8:00PM:</strong> Tune in for Final Presentations!</li>
              <li><strong>Afterwards:</strong> All submissions finished and added to this site.</li>


            </ul>
          </Panel>
          <Panel>
            <h2>How to participate:</h2>
            <ol>
              <li><a href="https://discord.gg/fTFgG9y">Join the Wonderville Discord Server for the kickoff!</a>!</li>
              <li>Hang out on <a href="https://discord.gg/fTFgG9y">Discord</a> Find a team, and chat about your ideas.</li>
              <li><a href="/submit">Submit</a> your project! (It doesn't need to be finished, just let us know you're participating)</li>
              <li>If you'd just like to watch and hang out, feel free to join the <a href="https://discord.gg/fTFgG9y">Discord</a> or watch on <a href="http://www.twitch.tv/WondervilleNYC">Wonderville on Twitch</a>.</li>
              <li>Come back to this site to update your submission and check others' work!</li>
              <li>Check the schedule for check-in times and other presentations</li>

            </ol>
          </Panel>
        </InstructionContainer>
        <SubmissionsHeader><span>{submissionCount}</span> Current Submissions:</SubmissionsHeader>
        <SubmissionGrid>
          {listSubmissions()}
          {submissions.length > submissionsToShow && <ShowMoreButton onClick = {showMore}>Show More</ShowMoreButton>}
        </SubmissionGrid>
      </ContentContainer>
    </>
  );
}

export default App;