import React from "react";
import App from "./App.tsx";
import Profile from "./Profile.tsx";
import SubmitPage from "./Submit.tsx";
import LoginPage from "./LoginPage.tsx";
import SignUpPage from "./SignUpPage.tsx";
import {
  BrowserRouter,
  Switch,
  Route
//  Link,
//  useRouteMatch,
//  useParams
} from "react-router-dom";

const Router = () => {
  return (
    <BrowserRouter>
      <Switch>
        <Route exact path="/" component ={App}/>
        <Route exact path="/profile" component ={Profile}/>
        <Route exact path="/submit" component ={SubmitPage}/>
        <Route exact path="/login" component ={LoginPage}/>
        <Route exact path="/signup" component ={SignUpPage}/>
      </Switch>
    </BrowserRouter>
  );
};

export default Router;
