import firebase from 'firebase'

const config = {
    apiKey: "AIzaSyCcSYQdwmrobK6Mpnfz8sXwFCtpozbvtas",
    authDomain: "quarantine-jam.firebaseapp.com",
    databaseURL: "https://quarantine-jam.firebaseio.com",
    projectId: "quarantine-jam",
    storageBucket: "quarantine-jam.appspot.com",
    messagingSenderId: "25959484238",
    appId: "1:25959484238:web:ec9e886e308c56f40d2c1f"
    
};
firebase.initializeApp(config);
export const provider = new firebase.auth.GoogleAuthProvider();
export const auth = firebase.auth();
export const storage = firebase.storage();
export default firebase;