import React, { useEffect, useState, useRef } from 'react';
import firebase, { auth, provider, storage } from './firebase.js';
import styled from 'styled-components'
import { isCompositeComponent } from 'react-dom/test-utils';
import { CustOrange, DarkTextColor } from './colors';
const Container = styled.div`
display: flex;
flex-direction: column;
background-color: ${CustOrange};
padding: 15px;
margin: 10px;
width: 296px;
position: relative;
color: ${DarkTextColor};
height: fit-content;

@media(max-width: 992px){
    width: 100%;
}

h2{
  font-size: 17px;
}

p{
//   padding: 5px 0px;
}
`

const Blurb = styled.div`
    margin-top: 5px;
    padding-bottom: 30px;
    img{
        float: left;
        // width: 80px;
        // height: 45px;
        width: 120px;
        height: 68px;
        padding-right: 10px;
        // padding-bottom: 5px;
        object-fit:cover;
    }
`

const LogoTray = styled.div`
width: 100%;

margin-top: 10px;
display: flex;
flex-direction: row;
justify-content: space-between;
color: #43464b !important;
position: absolute;
    bottom: 10px;
   
    width: 100%;
    left: 0;

    div{
        padding: 0 10px;
    }
a {
    color: #43464b !important;
    margin: 0 3px;
    &:hover{
        opacity: 0.7;
    }
}
`

type Props = {
    item: any;
}

const Submission = ({item}:Props) => {
    const [imgUrl, setImgUrl] = useState<any>('')
    const [buildUrl, setBuildUrl] = useState<any>('')
    
    useEffect(() => {
        getThumbURL(item.imageLoc);
        getBuildURL(item.buildLoc);
    })

    const getThumbURL = (imageLoc:any) => {
       var imageLoc = item.imageLoc;
        const gsReference = storage.refFromURL('gs://quarantine-jam.appspot.com/images/' + imageLoc)
        gsReference.getDownloadURL().then(function(url) {
            setImgUrl(url);
        })
    } 
    const getPlatformIcon = () =>{
        
        switch (item.platform){
            
            case "Windows":
                return (<i className="fab fa-windows"></i>)
                break;
            case "Mac":
                return (<i className="fab fa-apple"></i>)
                break;
            case "iOS":
                return (<i className="fab fa-app-store-ios"></i>)
                break;
            case "Android":
                return (<i className="fab fa-android"></i>)
                break;
            case "Web":
                return (<i className="fab fa-chrome"></i>)
                break;
            case "Physical":
                return (<i className="fas fa-scroll"></i>)
                break;
            case "Other":
                return (<i className="fas fa-question"></i>)
                break;
                default:
                    return (<i className="fas fa-question"></i>)
                    break;

        }
    }

    const getBuildURL = (buildLoc:any) => {
        var buildLoc = item.buildLoc;
         const gsReference = storage.refFromURL('gs://quarantine-jam.appspot.com/builds/' + buildLoc)
         gsReference.getDownloadURL().then(function(url) {
             setBuildUrl(url);
         })
     } 

    return (
        <Container>
            <h1>{item.title}</h1>
            <h2>by {item.username}</h2>
            <Blurb>
                {item.imageLoc && (<a target = "_blank" href = {imgUrl}><img src = {imgUrl}/></a>)}
                <p>{item.description}</p>
            </Blurb>
            <LogoTray>
                <div>
                    { item.buildLoc && (<a href = {buildUrl}><i className="fas fa-download"></i></a>) }{item.link && (<a target = "_blank" href = {item.link}><i className="fas fa-external-link-alt"></i></a>)}
                </div>
                <div>{getPlatformIcon()}</div>
            </LogoTray>
        </Container>
        
    )

}

export default Submission
