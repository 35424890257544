import React, { useEffect, useState, useRef } from 'react';
import firebase, { auth, provider, storage } from './firebase.js';
import NavBar from './NavBar';
import {ContentContainer} from './App';
import LoginModule, {LoginContainer, Field, SubButton} from './LoginModule';
import styled from 'styled-components';
import {CustGrey, DarkTextColor} from './colors';

const WebLink = styled(Field)`
    width: 400px;
`

const SubmissionForm = styled.form`
    margin: auto;
    width: 80%;
    color: ${DarkTextColor};
    background-color: ${CustGrey};
    padding: 20px;
    box-sizing: border-box;

    textarea{
        font-size: 16px;
        width: 100%;
        height: 100px;
        border:0;
        -webkit-appearance: none;
        box-sizing: border-box;
        resize: none;
        margin: 5px 0px;
    }

    label {
        display: flex;
        align-items: center;
        i{ margin: 0 3px;}

        @media(max-width: 992px){
            flex-direction: column;
            text-align: left;
        }
    }
    p { margin-bottom: 20px;}
`

const FileRow = styled.div`
width; 100%;
margin: 10px 0px;
font-family: 'Raleway';
display: flex;
align-items: center;
input{
    margin-left: 10px;
}

@media(max-width: 992px){
    flex-direction: column;
    text-align: left;
}

i{margin-right:5px; color: grey;}
`

const BuildRow = styled(FileRow)`
   justify-content: space-between;
   div{
       display: flex;
       align-items: center;

       @media(max-width: 992px){
        flex-direction: column;
        text-align: left;
    }
   }
`

const StatusText = styled.div`
text-align: center;
p{ margin: 10px 0;}
margin-bottom: 40px;
`
const ThumbnailPreview = styled.img`
    width: 150px;
    margin-bottom: 10px;
`
const SubmitPage = () => {
    const [user, setUser] = useState<any>(null);
    const [submission, setSubmission] = useState<any>(null);
    const [uploadInProgress, setUploadInProgress] = useState<boolean>(false);
    const [selectedPlatform, setSelectedPlatform] = useState<any>(null);
    const [currentSubmissionRef, setCurrentSubmissionRef] = useState<any>(null);
    const titleEl = useRef<any>(null);
    const descriptionEl = useRef<any>(null);
    const usernameEl = useRef<any>(null);
    const thumbnailEl = useRef<any>(null);
    const linkEl = useRef<any>(null);
    const buildEl = useRef<any>(null);
    const [imageAsFile, setImageAsFile] = useState<any>('')
    const [buildAsFile, setBuildAsFile] = useState<any>('')
    const [buildProgress, setBuildProgress] = useState<any>(null)
    const [thumbnailURL, setThumbnailURL] = useState<any>('')
    const [buildURL, setBuildURL] = useState<any>('')
    const [ready, setReady] = useState<boolean>(false)
    const [imageLoc, setImageLoc] = useState<any>(null)
    const [buildLoc, setBuildLoc] = useState<any>(null)

    useEffect(() => {
        // Update the document title using the browser API

        auth.onAuthStateChanged((user) => {
            if (user) {
                setUser(user);
                console.log(user)
                setReady(true)

              //  get the submission
                const thisSubmission = firebase.database().ref('submissions').orderByChild('user').equalTo(user.email)
                
                thisSubmission.on('value', (snapshot) => {
                    if (snapshot.val() !== null){
                        console.log(snapshot.val())
                        console.log(snapshot.val()[Object.keys(snapshot.val())[0]])
                        var snapshotContent = snapshot.val()[Object.keys(snapshot.val())[0]]
                        
                        if (snapshot.val() != null) { 
                            setSubmission(snapshot.val()[Object.keys(snapshot.val())[0]]); 
                            setCurrentSubmissionRef([Object.keys(snapshot.val())[0]])
                           
                        }
                        
                        if (snapshotContent.title){
                            titleEl.current.value = snapshotContent.title
                        }
                        if (snapshotContent.username){
                            usernameEl.current.value = snapshotContent.username
                        }
                
                        if (snapshotContent.platform){
                            setSelectedPlatform(snapshotContent.platform)
                        }

                        if (snapshotContent.link){
                            linkEl.current.value = snapshotContent.link
                        }

                        if (snapshotContent.imageLoc != null){
                            getThumbURL(snapshotContent.imageLoc);
                            setImageLoc(snapshotContent.imageLoc)
                        }

                        if (snapshotContent.description != null){
                            descriptionEl.current.value = snapshotContent.description
                        }
                        
                        if (snapshotContent.buildLoc != null){
                            getBuildURL(snapshotContent.buildLoc);
                            setBuildLoc(snapshotContent.buildLoc)
                        }
                    }
                })

               
                
            } else{
                setUser(null)
                setReady(true)
            
            }
        })

        
    },[user]);

    const getThumbURL = (imageLoc:string) => {
       
        const gsReference = storage.refFromURL('gs://quarantine-jam.appspot.com/images/' + imageLoc)
        gsReference.getDownloadURL().then(function(url) {
            setThumbnailURL(url)
        })
    } 

    const getBuildURL = (buildLoc:string) => {
       
        const gsReference = storage.refFromURL('gs://quarantine-jam.appspot.com/builds/' + buildLoc)
        gsReference.getDownloadURL().then(function(url) {
            setBuildURL(url)
            setBuildLoc(buildLoc)
        })
    } 

    const handleImageAsFile = (event:any) => {
        const image = event.target.files[0];
        var filesize = ((image.size/1024)/1024).toFixed(4);
        if (parseFloat(filesize) > 2){
            alert("Hey, that file is kinda big. Would you mind uploading an image under 2MB? Thanks!")
            thumbnailEl.current.value = null
            return
        }
        setImageAsFile(image);
        const storageRef = storage.ref();
        const uploadLocRef = storageRef.child('images/' + user.email + '/' + image.name);
        uploadLocRef.put(image).then(function(snapshot) {
            console.log('Uploaded a blob or file!');
            var newURL = getThumbURL(user.email + '/' + image.name);
            setThumbnailURL(newURL);
            setImageLoc(user.email + '/' + image.name)
          });
    }

    const handleBuildAsFile = (event:any) => {
        const build = event.target.files[0];
        var filesize = ((build.size/1024)/1024).toFixed(4);
        if (parseFloat(filesize) > 500){
            alert("Hey... so your build is weighing in at a hefty " + filesize + "MB. Do you think you could reduce that to under 500MB? If not, please use the link field to link to your build hosted on Dropbox or another platform." )
            buildEl.current.value = null
            return
        }
        setBuildAsFile(build);
        const storageRef = storage.ref();
        const buildUploadLocRef = storageRef.child('builds/' + user.email + '/' + build.name);
        var buildUploadTask = buildUploadLocRef.put(build)
          
        buildUploadTask.on('state_changed', function(snapshot){
            // Observe state change events such as progress, pause, and resume
            // Get task progress, including the number of bytes uploaded and the total number of bytes to be uploaded
            var progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
            setBuildProgress(Math.floor(progress) + '%');
            console.log('Upload is ' + progress + '% done');
            setUploadInProgress(true)
            switch (snapshot.state) {
              case firebase.storage.TaskState.PAUSED: // or 'paused'
                console.log('Upload is paused');
                break;
              case firebase.storage.TaskState.RUNNING: // or 'running'
                console.log('Upload is running');
                break;
            }
          }, function(error) {
            // Handle unsuccessful uploads
          }, function() {
            // Handle successful uploads on complete
            // For instance, get the download URL: https://firebasestorage.googleapis.com/...
            setBuildLoc(user.email + '/' + build.name)
            buildUploadTask.snapshot.ref.getDownloadURL().then(function(downloadURL) {
              console.log('File available at', downloadURL);
              setBuildProgress("Done")
              setUploadInProgress(false)
            });
          });
    }

    const handlePlatformChange = (event:any) => {
        setSelectedPlatform(event.target.value)
    }
    const finishSubmission = (event:any) => {
        event.preventDefault();
        if (uploadInProgress){
            // DONT LET THE USER SAVE YET!
            alert("Please wait for your build upload to complete before saving!")
          
        }
        const submissions = firebase.database().ref('submissions');
       

        var newURL = getBuildURL(user.email + '/' + buildAsFile.name);
        setBuildURL(newURL);
        //TODO: Allot for no image or build
        const item = {
            title: titleEl.current.value,
            user: user.email,
            username: usernameEl.current.value,
            description: descriptionEl.current.value,
            platform: selectedPlatform,
            imageLoc: imageLoc,
            buildLoc: buildLoc,
            link: linkEl.current.value,
            lastUpdated: Date.now()
        }
        if (currentSubmissionRef !== null){

            firebase.database().ref('submissions/' + currentSubmissionRef).set(item)
            alert("Thanks, your submission has been updated!")
        } else {
            submissions.push(item);
            alert("Thanks, your submission has been saved!")
            document.location.href = "/"
        }
        
       
    }


    return (
        <>
        <NavBar />
        {ready &&
        <ContentContainer>
            {user ?

                (
                    <>
                        {submission && (
                            
                            <StatusText>
                                <h1>You have an existing submission called {submission.title}!</h1>
                                <p>Feel free to make changes below to your submission at any time.</p>
                            </StatusText>
                            )
                        }
                    
                        <SubmissionForm onSubmit={finishSubmission}>
                            <h2>{submission ? 'Manage ' : 'Enter '}Your Submission</h2>
                            <p>(Don't worry, you can return to make changes at any time!)</p>
                            <Field maxLength={50} ref = {usernameEl} type="text" name="username" placeholder="What's your name / team name?" required />
                            <Field maxLength={50} ref = {titleEl} type="text" name="title" placeholder="What's your game's title?" required />
                            <textarea maxLength={300} ref = {descriptionEl} name="description" placeholder="Describe your game!" required />
                            <FileRow>Platform: 
                            <label>
                                <input
                                    type="radio"
                                    name="platform"
                                    value="Windows"
                                    checked={selectedPlatform === "Windows"}
                                    onChange={handlePlatformChange}
                                    className="form-check-input"
                                />
                                <span><i className="fab fa-windows"></i>Windows</span>
                            </label>
                            <label>
                                <input
                                    type="radio"
                                    name="platform"
                                    value="Mac"
                                    checked={selectedPlatform === "Mac"}
                                    onChange={handlePlatformChange}
                                    className="form-check-input"
                                />
                                <span><i className="fab fa-apple"></i>Mac</span>
                            </label>
                            <label>
                                <input
                                    type="radio"
                                    name="platform"
                                    value="iOS"
                                    checked={selectedPlatform === "iOS"}
                                    onChange={handlePlatformChange}
                                    className="form-check-input"
                                />
                                <span><i className="fab fa-app-store-ios"></i>iOS</span>
                            </label>
                            <label>
                                <input
                                    type="radio"
                                    name="platform"
                                    value="Android"
                                    checked={selectedPlatform === "Android"}
                                    onChange={handlePlatformChange}
                                    className="form-check-input"
                                />
                                <span><i className="fab fa-android"></i>Android</span>
                            </label>
                            <label>
                                <input
                                    type="radio"
                                    name="platform"
                                    value="Web"
                                    checked={selectedPlatform === "Web"}
                                    onChange={handlePlatformChange}
                                    className="form-check-input"
                                />
                                <span><i className="fab fa-chrome"></i>Web</span>
                            </label>
                            <label>
                                <input
                                    type="radio"
                                    name="platform"
                                    value="Physical"
                                    checked={selectedPlatform === "Physical"}
                                    onChange={handlePlatformChange}
                                    className="form-check-input"
                                />
                                <span><i className="fas fa-scroll"></i>Physical/Paper</span>
                            </label>
                            <label>
                                <input
                                    type="radio"
                                    name="platform"
                                    value="Other"
                                    checked={selectedPlatform === "Other"}
                                    onChange={handlePlatformChange}
                                    className="form-check-input"
                                />
                                <span><i className="fas fa-question"></i>Other</span>
                            </label>
                            </FileRow>
                            <FileRow><i>(Optional)</i> Upload a screenshot/thumbnail: <input ref = {thumbnailEl} type="file" onChange={handleImageAsFile} name = "thumbnail" accept="image/png, image/jpeg"></input></FileRow>
                            {thumbnailURL && (<ThumbnailPreview src = {thumbnailURL} />)}
                            <BuildRow>
                                <div>
                                    <i>(Optional)</i> Upload a playable build: <input ref = {buildEl} type="file" onChange={handleBuildAsFile} name = "build"  accept="zip,application/octet-stream,application/zip,application/x-zip,application/x-zip-compressed"></input>
                                </div>
                               
                                {buildProgress ? (
                                    <span>Upload Progress: {buildProgress} </span>
                                ) : (
                                    buildURL && (
                                        <div><i className="fas fa-download"></i><a href = {buildURL}>Download Current Build</a></div>
                                    )
                                )}
                            </BuildRow>
                            <FileRow>
                            <i>(Optional)</i> Enter a link to a website or build: <WebLink ref = {linkEl} type="url" name="title" placeholder="http://www.yoursite.com" />
                            </FileRow>
                            <SubButton type ="submit">Save</SubButton>

                        </SubmissionForm>
                    </>
                ) : (
                    <div>
                    <LoginModule prevPage = "/submit" title={"Looks like you're not logged in! Log in to make a submission."}/>
                    </div>
                )
            }
        
        </ContentContainer>
            }
        </>
    )

}

export default SubmitPage